import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import formatDate from "@util/formatDate"
import Bio from "../components/bio"
import slugger from "github-slugger"
import Avatar from "./Avatar"

const DISPLAY_TOC = false
const shortcodes = { Link } // Provide common components here

export default function PageTemplate(props) {
  const { data, location } = props
  const { mdx } = data
  const siteTitle = data?.site.siteMetadata.title || `Title`
  let featuredImage =
    mdx?.frontmatter?.featuredImage?.childImageSharp?.fluid?.src
  featuredImage = featuredImage && `https://giovannibenussi.com${featuredImage}`

  return (
    <Layout location={location} title={siteTitle} className="bg-white-500">
      <Helmet>
        <script src="https://www.googletagmanager.com/gtag/js?id=G-KM87KM1KX1"></script>
        <script>{`
          window.dataLayer = window.dataLayer || []; function gtag()
          {dataLayer.push(arguments)}
          gtag('js', new Date()); gtag('config', 'G-KM87KM1KX1');
        `}</script>
      </Helmet>
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description || mdx.excerpt}
        image={featuredImage}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <Bio />
        <header className="border-b border-grey-500 content-horizontal-padding">
          {false && (
            <Img
              fluid={mdx.frontmatter.featuredImage?.childImageSharp?.fluid}
              className="w-auto"
              style={{ height: "15rem" }}
            />
          )}
          <p className="text-center text-gray-500">
            {formatDate(mdx.frontmatter.date)}
          </p>
          <h1 className="text-6xl mb-8 font-semibold text-center">
            {mdx.frontmatter.title}
          </h1>
          <Avatar />
        </header>
        <div className="blog-post-content">
          {DISPLAY_TOC && (
            <>
              <h2>Content</h2>
              <ul>
                {mdx.headings
                  .filter(heading => heading.depth === 2)
                  .map(header => (
                    <li key={header}>
                      <Link to={`#${slugger.slug(header.value)}`}>
                        {header.value}
                      </Link>
                    </li>
                  ))}
              </ul>
            </>
          )}
          <MDXProvider components={shortcodes}>
            <MDXRenderer headings={mdx.headings}>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
        <hr />
        <footer></footer>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query BlogPostQuery($id: String) {
    site {
      siteMetadata {
        title
      }
    }

    mdx(id: { eq: $id }) {
      id
      body

      headings {
        depth
        value
      }

      frontmatter {
        date
        description
        title

        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
