import React from "react"
import Image from "./giovanni-benussi-avatar.jpg"

function Avatar() {
  return (
    <div>
      <div className="flex space-x-2 items-center justify-center mb-4">
        <img className="rounded-full w-14 h-14" src={Image} alt="" />
        <div>
          <div>Giovanni Benussi</div>
          <a
            href="https://twitter.com/giovannibenussi"
            target="_blank"
            rel="noreferrer"
          >
            @giovannibenussi
          </a>
        </div>
      </div>
    </div>
  )
}

export default Avatar
